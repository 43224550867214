<template>
    <div id="app_div">
          <van-overlay :show="load_show" >
             <div style="position: absolute;top: 45%;left: 44%;">
                <van-loading size="24px"  color="white" vertical>加载中...</van-loading>
             </div>
        </van-overlay>
        <div class="label_box">
        </div>
         <!-- <div class="label_box" style="text-align:center;color:white;padding-top:10px;">
            <h1>扳手互联车辆估价系统 <br> 个人用户估价软件</h1>
        </div> -->
        <div class="btn_box">
        </div>
         <div class="register_div" v-show="show">
            <div class="tabs_div">
            </div>
            <div class="tab_body">
                <!-- <div class="tab_item">
                    <div class="tab_item_field field">&nbsp;&nbsp;手 &nbsp; 机</div>
                    <div class="tab_item_field">                        
                        <input type="number" v-model="loginForm.mobile" placeholder="请输入手机号" />
                    </div>
                    <div :class="{'tab_item_field':true,'login_button':!code_btn}" ><button @click="login_code" :disabled="code_btn">{{code_msg}}</button></div>
                </div>
                <div class="tab_item">
                    <div :class="{'tab_item_field':true,'field':true}">&nbsp;&nbsp;验证码</div>
                    <div :class="{'tab_item_field':true,'login_button':true}">     
                        <input type="number" v-model="loginForm.code"  placeholder="请输入验证码" />
                    </div>
                </div> -->
                <div class="tab_item">
                    <van-field
                        v-model="loginForm.mobile"
                        required
                        label="手机号"
                        placeholder="请输入手机号"
                        :error-message="loginForm.mobile_err"
                    />
                </div>
                <div class="tab_item">
                    <van-field
                        v-model="loginForm.code"
                        center
                        clearable
                        label="短信验证码"
                        placeholder="请输入短信验证码"
                        >
                        <template #button>
                            <van-button size="small" :disabled ='code_second<60' @click="login_code" type="primary">{{code_msg}}</van-button>
                        </template>
                    </van-field>
                </div>
            </div>
            <div class="tab_footer">
                <div style="height:30px;"></div>
                <div style="padding:0px 10px;">
                    <van-button type="primary" @click="userLogin" class="login_btn" block>验证登录</van-button>
                </div>
                <div style="height:20px;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { Overlay,Loading,Button,Field,Dialog } from 'vant';
import {tokenStore,loginUrlSotre} from '../../store/token'
import {balanceUser,personalUser} from '../../store/personal'
import {wxLogin} from  '../../api/login'
import {codeToOpenid} from '../../api/wxAuth'
import {sendRegisterCodeForPerson,wxScrapUser} from '../../api/personal'
export default ({
    name:'mAgentHome',
    components:{
        [Overlay.name]: Overlay,
        [Loading.name]: Loading,
        [Button.name]: Button,
        [Field.name]:Field,
        [Dialog.Component.name]: Dialog.Component,
    },
    data(){
        return {
            active: 0,
            load_show:false,
            openId:'',
            show:true,
            code_interval:undefined,
            code_second:60,
            code_btn:false,
            code_msg:"发送验证码",
            loginForm:{
                mobile:'',
                mobile_err:'请输入手机号码',
                code:'',
                code_err:'请输入验证码',
            },
            go_page:"/personalIndex",
            ifFirstLogin:true,
        }
    },
    methods:{
         wxLogin(openid){
            console.log('openid');
            console.log(openid);
            this.load_show = true;
             wxLogin({openId:openid,state:0}).then(res=>{
                console.log('login');
                console.log(res);
                this.load_show = false;
                if(res.id&&res.token){
                    var data = res;
                    tokenStore.set('Bearer '+res.token)
                    delete res.token
                    personalUser.set(data);
                    this.$router.replace({path:this.go_page});
                }else{
                    this.$toast("微信未绑定");
                    this.show = true;
                }
            });
        },
        mobileBlur(){
            console.log("mobile_blur");
            this.loginForm.mobile_err = !this.loginForm.mobile?"请输入手机号码":' ';
            if(!this.$util.checkPhone(this.loginForm.mobile)){
                this.loginForm.mobile_err = "手机号码格式错误";
            }
        },
        userLogin(){
            var body = {
              phone:this.loginForm.mobile,
              code:this.loginForm.code,
              openId:this.openId
            };
            wxScrapUser(body).then(res=>{
                if(res.status&&res.data&&res.data.token){
                    this.$toast(res.message||"登录成功");
                    var jsonObj = res.data;
                    tokenStore.set('Bearer '+ res.data.token)
                    delete jsonObj.token
                    personalUser.set(jsonObj);
                    loginUrlSotre.set("/personal")
                    this.$router.replace({path:this.go_page});
                }else{
                    this.$toast(res.message);
                }
                console.log(res);
            });
        },
        login_code(){
            console.log("login_code");
            console.log(this.code_interval);
            this.mobileBlur();
            if(!this.$util.checkPhone(this.loginForm.mobile)){
                this.loginForm.mobile_err = "手机号码格式错误";
                return;
            }
            if(!this.code_interval){
                console.log('mobile');
                console.log(this.loginForm.mobile);
                sendRegisterCodeForPerson({mobile:this.loginForm.mobile}).then((res)=>{
                   this.$toast(res.message);
                });
                this.code_btn = true;
                this.code_interval = setInterval(() => {
                    this.code_msg ="重新发送"+ this.code_second--;
                    if(this.code_second<=0){
                        this.code_msg = "发送验证码";
                        this.code_btn = false;
                        window.clearInterval(this.code_interval);
                        this.code_second = 60;
                        this.code_interval= undefined;
                    }
                }, 1000);
            } 
        },
        getCode () { // 非静默授权，第一次有弹框
            this.code = ''
            var local = window.location.href // 获取页面url
            var appid = this.$enum.wx_appid;
            var obj = this.getUrlCode();
            this.code = obj.code // 截取code
            this.state = obj.state // 截取code
            if (!this.code) { // 如果没有code，则去请求
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=0&connect_redirect=1#wechat_redirect`;
            } else {
                codeToOpenid({code:this.code})
                .then(res=>{
                    this.$toast.clear();
                    res =res.data;
                    if(res.code == 500){
                        this.$toast.fail(res.msg);
                    }else{
                        if(!res.data){
                            this.$toast.fail('请求错误，请刷新重试！');
                            return;
                        }
                        this.openId = res.openId;
                        localStorage.setItem('openId', res.openId);
                        this.wxLogin(res.openId);
                    }
                })
            }
        },
        getUrlCode() { // 截取url中的code方法
                var url = location.search
                this.winUrl = url
                var theRequest = new Object()
                if (url.indexOf("?") != -1) {
                    var str = url.substr(1)
                    var strs = str.split("&")
                    for(var i = 0; i < strs.length; i ++) {
                        theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
                    }
                }
                return theRequest
            },
        },
    mounted(){       
        var page = this.$route.query.go_index;
        debugger
        if(page){
            this.go_page = page;
            balanceUser.set(true);
            console.log('go_page',page)
        }
        var openid =window.localStorage.getItem('openId');
        console.log(openid);
        this.openId = openid;
        var personalTicket = ()=>{
            if(this.$global.isWeiXin){
                if(!openid){ // 如果缓存localStorage中没有微信openId，则需用code去后台获取
                    this.getCode();
                }else{
                    this.wxLogin(openid);
                }
            }
        }
        if(this.ifFirstLogin){
            this.$dialog.confirm({
                title: '提示',
                message: '是否使用当前微信进行登录？',
            })
            .then(() => {
                personalTicket();
            })
            .catch(() => {});
        }else{
            personalTicket();
        }
    }
})
</script>
<style scoped>
@import "./personal.css";
</style>